<template>
  <VueDraggableResizable
    :x="item.x" :y="item.y" :w="item.w" :h="item.h"
    :handles="!tagging ? ['tl','tm','tr','mr','br','bm','bl','ml'] : []"
    :resizable="true"
    @resizing="onResize"
    :active="item.active && !tagging"
    @dragging="onDrag"
    @activated="onActivated"
    @deactivated="onDeactivated"
    :prevent-deactivation="preventDeActivation"
    :lock-aspect-ratio="item.shape == 'circle'"
    :parent="true"
    :draggable="!tagging"
    :grid="[10, 10]"
    id="card-container"
  >
    <div 
      class="butt fill"
      :class="{circle : item.shape == 'circle', chosen : tagIsChosen(item.tag_id)}"
      v-if="item.type == 'tag'"
      :style="itemStyle"
      @click="handleTagClick( all_tags.find(t => t.id == item.tag_id) )"
    >
      {{item.text}}
      <div class="size" v-if="item.active">
        {{ item.h }} x {{ item.w }}
      </div>
    </div>
    <div
      v-else-if="item.type == 'group'" 
      class="group"
    >
      <div class="group-title" :style="`display: flex; justify-content: ${item.justifyContent}`" v-if="item.showGroupName">
        {{ item.text }}
      </div>
      <div v-if="group_tags && group_tags.length" class="group-cont fill" :style="itemsContStyle">
        <div
          v-for="tag in group_tags"
          :key="tag.id"
          @click="handleTagClick(tag)"
          :style="{
            ...itemStyle,
            border: `2px solid ${tag.map_color}`
          }"
          class="butt group-cont-item"
          :class="{'chosen' : tagIsChosen(tag.id)}"
        >
          {{ tag.tag_name }} 
            <span style="text-transform: none; border: 1px solid grey; border-radius: 5px; padding: 1px 3px;" outlined v-if="tag.hotkey">{{ tag.hotkey }}</span>
        </div>
      </div>
      <div style="text-align: center;" v-else>
        <div class="mb-1">
          No tags yet in {{item.text}}
        </div>
        <v-btn x-small @click="editingTags = true">
          <v-icon>mdi-plus</v-icon>
          Add tags
        </v-btn>
      </div>
    </div>
    <div
      v-else-if="item.type == 'dropdown'" 
      class="group"
    >
      <div class="group-title" v-if="item.showGroupName">
        {{ item.text }}
      </div>
      <div v-if="group_tags && group_tags.length" class="group-cont fill m-2">
        <v-autocomplete
          :multiple="!group_by_id(item.tag_group_id).one_tag_only"
          dense
          :input-value="group_tags_by_id(item.tag_group_id)"
          :items="group_tags"
          deletable-chips
          :small-chips="!group_by_id(item.tag_group_id).one_tag_only"
          @input="handleSelectChange({tags: $event, group_id: item.tag_group_id})"
          v-prevent-keys
          solo
          :single-line="true"
          :label="item.text"
          :clearable="true"
          no-data-text="Not found"
          item-text="tag_name"
          item-value="id"
        >
        </v-autocomplete>
      </div>
      <div style="text-align: center;" v-else>
        <div class="mb-1">
          No tags yet in {{item.text}} 🥺
        </div>
        <v-btn x-small @click="editingTags = true">
          <v-icon>mdi-plus</v-icon>
          Add tags
        </v-btn>
      </div>
    </div>
    <div
      v-else-if="item.type == 'map'"
      class="pa-1"
    >
      <v-btn 
        v-if="tagging"
        @click="ERASE_MAP_POINTS()"
        style="position: absolute; bottom: 0px; right: 10px; z-index: 3;" 
        fab 
        x-small
      >
        <v-icon>mdi-eraser</v-icon>
      </v-btn>
      <Map
        :offsetY="item.y"
        :offsetX="item.x"
        :url="item.map_base_url"
        :colorOverride="map_color"
        :maxHeight="item.h"
        :maxWidth="item.w"
        :hideButtons="true"
        @input="setMapPoints({points: $event, map_base_id: item.map_base_id, video_id: $route.params.id});"
        :value="map_points(item.map_base_id)"
        :key="item.h + item.w"
      ></Map>
    </div>
    <div v-else-if="item.type == 'text'">
      <v-text-field
        solo
        v-if="item.active"
        :value="item.text"
        @input="PUT_ITEM({ ...item, text: $event })"
      ></v-text-field>
      <div v-else style="textAlign: center; borderRadius: 5px; justifyContent: center;" :style="itemStyle" class="fill">
        <span>
        {{item.text}}
        </span>
      </div>
    </div>
    <v-dialog max-width="800" v-model="editingTags" v-if="editingTags">
      <v-card>
        <Tags :groups_only_ids="[item.tag_group_id]" v-if="editingTags"></Tags>
      </v-card>
    </v-dialog>
  </VueDraggableResizable>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Map from '../maps/Map.vue'
import Tags from '../../views/Tags.vue'
export default {
  props: ['item', 'tagging', 'chosen', 'map_value'],
  shortcuts: {
    keydown: function(e) {
      if(e.key == 'Shift') {
        this.SET_PREVENT_DEACTIVATION(true)
      }
    }
  },
  components: {
    VueDraggableResizable,
    Map,
    Tags,
  },
  data: () => ({
    editingTags: false
  }),
  methods: {
    itemClicked(id) {
      console.log(id)
    },
    handleTagClick(tag) {
      if(this.tagging) {
        this.handleTagClicked(tag)
      }
    },
    tagIsChosen(id) {
      return !!this.tags.find(t => t.id == id)
    },
    onResize(x, y, w, h) {
      this.$emit('change', {
        ...this.item,
        x, y, w, h
      })
    },
    onDrag(x, y) {
      this.$emit('change', {
        ...this.item,
        x, y
      })
    },
    onActivated() {
      if(this.tagging) return

      this.$emit('change', {
        ...this.item,
        active: true
      })
    },
    onDeactivated() {
      this.$emit('change', {
        ...this.item,
        active: false
      })
    },
    ...mapActions('tagging', [
      'handleTagClicked',
      'setMapPoints',
      'handleSelectChange'
    ]),
    ...mapMutations('template', ['SET_PREVENT_DEACTIVATION', 'PUT_ITEM']),
    ...mapMutations('tagging', ['SET_MAP_POINTS', 'ERASE_MAP_POINTS']),
  },
  computed: {
    ...mapGetters('tag', [
      'tag_groups',
      'all_tags',
      'tag_by_id',
      'group_by_id'
    ]),
    ...mapGetters('tagging', [
      'tags',
      'map_points',
      'map_color',
      'group_tags_by_id',
      'answers_tags_by_group_id'
    ]),
    ...mapGetters('template', [
      'preventDeActivation'
    ]),
    itemStyle() {
      return {
        fontSize: this.item.fontSize + 'px',
        backgroundColor: this.item.backgroundColor,
        color: this.item.textColor,
      }
    },
    itemsContStyle() {
      return {
        justifyContent: this.item.justifyContent
      }
    },
    group_tags() {
      if(!this.item) return
      let group = this.group_by_id(this.item.tag_group_id)
      if(!group) return
      let show_in_join_with = JSON.parse(group.show_in_join_w_group_tags)

      if(!show_in_join_with) {
        return this.tag_groups.find(g => g.id == this.item.tag_group_id)?.tags
      } else {
        let tags = []

        // add chosen tags from the mirroring group
        show_in_join_with?.forEach(id => {
          tags = tags.concat(this.group_tags_by_id(id))
        })

        tags = tags.concat(this.answers_tags_by_group_id(group.id))
        return tags
      }
    },
    group_tags_names() {
      return this.group_tags?.map(g => g.tag_name)
    }
  }
}
</script>

<style lang="scss" scoped>
  .fill {
    height: 100%;
    width: 100%;
    display: flex;
  }

  .butt {
    border-radius: 7px; 
    text-align: center;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    color: white;
    text-transform: uppercase;
    vertical-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    background-color: rgb(226, 226, 226);
  }

  .circle {
    border-radius: 50%;
  }

  .size {
    color: lightgrey;
    position: absolute;
    left: calc(50% - 25px);
    bottom: 3px;
    font-size: 10px;
  }

  .group {
    &-title {
      text-transform: uppercase;
    }

    &-cont {
      display: flex;
      flex-wrap: wrap;

      &-item {
        padding: 2px 4px;
        margin: 2px;
      } 
    }
  }

  .chosen {
    -webkit-box-shadow: inset 0px 2px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 2px 10px 0px rgba(0,0,0,0.75);
    box-shadow: inset 0px 2px 10px 0px rgba(0,0,0,0.75);
    border: 2px solid;
  }
</style>