<template>
  <v-sheet>
    <v-row class="buttontemplate-create">
      <v-col @mouseover="SET_PREVENT_DEACTIVATION(false)" cols="8">
        <button-template/>
      </v-col>
      <v-col cols="4" v-if="!oneItemIsActive">
        <v-tabs
          v-model="tab"
          align-tabs="center"
        >
          <v-tab :value="0">Add items</v-tab>
          <v-tab :value="1">Template rules</v-tab>
          <!-- <v-tab :value="2">Edit form</v-tab> -->
          <!-- <v-tab :value="2">Settings</v-tab> -->
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item :value="0">
            <add-item/>
          </v-window-item>
          <v-window-item :value="1">
            <edit-rules/>
          </v-window-item>
          <!-- <v-window-item :value="2">
            <EditForm/>
          </v-window-item> -->
          <!-- <v-window-item :value="2">
            <EditSettings/>
          </v-window-item> -->
        </v-window>
      </v-col>
      <v-col @mouseover="SET_PREVENT_DEACTIVATION(true)" cols="4" v-else>
        <ButtonTemplateItemEditor/>
      </v-col>
    </v-row>
      <v-btn :disabled="saving" style="position: absolute; top: 10px; left: 30px;" color="primary" @click="save({exit: 0})">
        <v-icon class="mr-3">mdi-content-save-outline</v-icon> Save
      </v-btn>
      <v-btn :disabled="saving" style="position: absolute; top: 60px; left: 30px;" color="primary" @click="save({ exit: 1})">
        <v-icon class="mr-3">mdi-arrow-left-top</v-icon> Save & Exit
      </v-btn>
      <v-btn :disabled="saving" style="position: absolute; top: 110px; left: 30px;" @click="$router.go(-1)">
        <v-icon class="mr-3">mdi-close</v-icon> Quit
      </v-btn>
      <!-- <v-btn v-if="id" :disabled="saving" variant="tertiary" style="position: absolute; bottom: 30px; left: 30px;" @click="deleting = true">
        <v-icon class="mr-3">mdi-delete</v-icon> Delete
      </v-btn> -->
      <confirmation-dialog
        :show="deleting"
        @decline="deleting = false"
        @accept="startDeleteTemplate()"
        text="Are you sure?"
        subText="Are you sure you want to delete this button template?"
        btnText="delete"
        btnColor="red"
      ></confirmation-dialog>
  </v-sheet>
</template>

<script>
import AddItem from './AddItem.vue'
import ButtonTemplate from './ButtonTemplate.vue'
import ButtonTemplateItemEditor from './ButtonTemplateItemEditor.vue'
// import EditForm from './EditForm.vue'
import EditRules from './EditRules.vue'
// import EditSettings from './EditSettings.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmationDialog from '../ConfirmationDialog.vue'

export default {
  components: { 
    // EditSettings, 
    EditRules, 
    // EditForm, 
    AddItem, 
    ButtonTemplate, 
    ButtonTemplateItemEditor,
    ConfirmationDialog 
  },
  data: () => ({
    tab: 0,
    saving: false,
    deleting: false
  }),
  created() {
    if(this.$route.params.template_id) {
      this.initTemplate(this.$route.params.template_id)
    } else {
      this.clearTemplate()
    }

    if(this.$route.params.sport_id) {
      this.initSportTags(this.$route.params.sport_id)
    } else {
      this.initTags()
    }
  },
  methods: {
    ...mapMutations('template', [
      'SET_PREVENT_DEACTIVATION',
    ]),
    ...mapActions('template', [
      'postSportButtonTemplate',
      'postTeamButtonTemplate',
      'initTemplate',
      'clearTemplate',
      'deleteTemplate'
    ]),
    ...mapActions('noti', [
      'error',
      'success',
      'info'
    ]),
    ...mapActions('tag', [
      'initTags',
      'initSportTags'
    ]),
    startDeleteTemplate() {
      this.deleteTemplate(this.id)
        .then(() => {
          this.info('Template deleted')
          this.$router.go(-1)
        })
        .catch(e => {
          this.error(e)
        })
    },
    save({ exit }) {
      this.saving = true
      if(this.$route.params.sport_id) {
        this.postSportButtonTemplate(this.$route.params.sport_id)
          .then(() => {
            this.success('template saved!')
            this.saving = false
            this.$router.push(`/tiimi-admin/sports/${this.$route.params.sport_id}/templates`)
          })
          .catch(e => {
            this.error(e)
          })
          .finally(() => {
            this.saving = false
          })
      } else {
        this.postTeamButtonTemplate()
          .then(() => {
            this.success('Button template saved!')
            this.saving = false
            if(exit) this.$router.go(-1)
          })
          .catch(e => {
            this.error(e)
          })
          .finally(() => {
            this.saving = false
          })
      }
    }
  },
  computed: {
    ...mapGetters('template', [
      'oneItemIsActive',
      'activeItem',
      'id'
    ])
  }
} 
</script>

<style lang="scss" scoped>
  .buttontemplate-create {
    height: 100vh;
  }

  .octagon {
    width: 100%;
    height: 100%;
    background: red;
    position: relative;
  }
  .octagon:before {
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 29px solid red;
    border-left: 29px solid #ffffff;
    border-right: 29px solid #ffffff;
  }
  .octagon:after {
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 29px solid red;
    border-left: 29px solid #ffffff;
    border-right: 29px solid #ffffff;
  }

</style>