<template>
  <v-sheet class="pa-5" style="height: 100%; width: 100%; overflow-y: scroll;">
    <v-row>
      <v-col cols="6">
        <v-card-text>
          Template name
        </v-card-text>
        <v-text-field dense solo @input="SET_TEMPLATE_NAME($event)" :value="template_name"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-switch v-tooltip="'If selected, clicking a button saves the event automatically.'" @change="SET_SETTINGS({
          save_on_tag: settings.save_on_tag, save_on_map: $event
        })" :input-value="settings.save_on_map" :value="settings.save_on_map" label="Save on map click">

        </v-switch>
        <v-switch v-tooltip="'If selected, adding a map point saves the event automatically.'" @change="SET_SETTINGS({
          save_on_tag: $event, save_on_map: settings.save_on_map
        })" :input-value="settings.save_on_tag" :value="settings.save_on_tag" label="Save on tag click">

        </v-switch>
      </v-col>
    </v-row>
    <v-card-title>
      Add item
    </v-card-title>
    <v-divider></v-divider>
    <v-sheet>
      <v-card-title>
        Buttons
      </v-card-title>
      <v-row class="py-5">
        <v-col class="center">
          <v-btn color="primary" @click="choosingTag = true; addingItem = {
            type: 'tag',
            shape: 'rectangle',
            text: 'Tag'
          }">
            Button
          </v-btn>
        </v-col>
        <v-col class="center">
          <v-btn color="primary" fab @click="choosingTag = true; addingItem = {
            type: 'tag',
            shape: 'circle',
            text: 'Tag'
          }">
            Button
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-title>
        Button groups
      </v-card-title>
      <v-row class="py-5">
        <v-col class="center">
          <v-card @click="choosingTagGroup = true; addingItem = {
            type: 'group'
          }">
            <div style="font-weight: bold; text-align: center;">
              Basic
            </div>
            <v-img src="../../assets/template_icon.png"></v-img>
          </v-card>
        </v-col>
        <v-col class="center">
          <v-card @click="choosingTagGroup = true; addingItem = {
            type: 'dropdown'
          }">
            <div style="font-weight: bold; text-align: center;">
              Dropdown
            </div>
            <v-img src="../../assets/template_icon_dropdown.png"></v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-title>
        Map
      </v-card-title>
      <v-row>
        <v-col 
          v-for="base in map_bases"
          :key="base.id"
        >
          <v-card height="150" width="150" class="center"  @click="addingItem = {type: 'map'}; handleItemAdded({
            map_base_id: base.id,
            map_base_url: base.url
          })">
            <v-img
              :src="base.url"
            >
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-card-title>
        Text
      </v-card-title>
      <v-row>
        <v-col class="center">
          <v-card height="30" width="100" class="center" @click="addingItem = {type: 'text'}; handleItemAdded({
          })">
            Text
          </v-card>
        </v-col>
      </v-row> -->
    </v-sheet>
    <tag-adder-dialog
      :sport_id="$route.params.sport_id"
      :show="choosingTag || choosingTagGroup"
      :addable="true"
      @close="choosingTag = false; choosingTagGroup = false;"
      @tag_clicked="handleItemAdded($event)"
      @group_clicked="handleItemAdded($event)"
      :onlyGroupChoosable="choosingTagGroup"
      :hideExtraButtons="true"
    ></tag-adder-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TagAdderDialog from '../tag/TagAdderDialog.vue'

export default {
  components: { TagAdderDialog },
  data: () => ({
    choosingTag: false,
    choosingTagGroup: false,
    addingItem: null
  }),
  created() {
    this.initSportMapBases(this.$route.params.sport_id || this.currentSportId)
  },
  methods: {
    ...mapActions('template', [
      'addItem'
    ]),
    ...mapMutations('template', [
      'SET_TEMPLATE_NAME',
      'SET_SETTINGS'
    ]),
    ...mapActions('map', [
      'initSportMapBases'
    ]),
    handleItemAdded(e) {
      let type = this.addingItem.type
      let item = {
        // general
        ...this.addingItem, // type and shape maybe
        text: this.itemText(e),
        id: 100,

        // position & size
        w: this.addingItem.type == 'group' ? 300 : 100,
        h: this.addingItem.shape == 'tag' ? 40 : 100,
        x: 10,
        y: 10,

        // active
        active: true,

        // style
        justifyContent: 'left', // center, left, right, space-between, space-around
        fontSize: 20,
        backgroundColor: '#2297F3',
        textColor: '#ffffff',
        activeBackgroundColor: '#ffffff',
        activeTextColor: '#ffffff',
        showGroupName: true,

        // tag, group and map info
        tag_id: type == 'tag' ? e.id : null,
        tag_group_id: type == 'group' || type == 'dropdown' ? e.id : e.group_id,
        tag_name: this.itemText(e),
        tag_group_name: this.itemText(e),
        map_base_id: e.map_base_id,
        map_base_url: e.map_base_url
      }

      this.addItem(item)

      this.choosingTag = false
      this.choosingTagGroup = false
      this.addingItem = null
    },
    itemText(e) {
      if(this.addingItem.type == 'tag') return e.tag_name
      else if (this.addingItem.type == 'group') return e.group_name
      else if (this.addingItem.type == 'dropdown') return e.group_name
      return null
    }
  },
  computed: {
    ...mapGetters('map', [
      'map_bases'
    ]),
    ...mapGetters('template', [
      'template_name',
      'settings'
    ]),
    ...mapGetters('user', [
      'currentSportId'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .center {
    display: flex; 
    justify-content: space-around; 
    align-items: center;
  }
</style>