<template>
  <div :class="{ 'team': tagging, 'mt-10': !tagging }" :style="`width: 400px; height: 650px; align-self: center; margin: 0 auto; border: ${tagging ? 'none;' : '3px dashed grey;'}`">
    <button-template-item
      :prevent-deactivation="true"
      @change="itemChanged($event)"
      v-for="(item, i) in useItems"
      :key="item.id || i + 'edititem'"
      :tagging="tagging"
      :item="item"
      :tags="tags"
    ></button-template-item>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import ButtonTemplateItem from './ButtonTemplateItem.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  props: ['init_items', 'team', 'tagging', 'value'],
  components: { ButtonTemplateItem },
  shortcuts: {
    keydown: function(e) {
      if(e.keyCode == 8) {
        this.REMOVE_ACTIVE()
      } else if(e.keyCode == 67 && e.metaKey) {
        this.copyActive()
      } else if(e.keyCode == 86 && e.metaKey)  {
        this.pasteClipboard()
      }
    }
  },
  created() {
    if(!this.$route.params.sport_id) this.initTags()
  },
  mounted() {
    window.onkeydown = function(e) {
        if(!this.keybinds) return
        e = e || window.event;
        var charCode = e.keyCode || e.which;
        if (charCode === 32) {
          e.preventDefault();
          return false;
        }
    }
  },
  data: () => ({

  }),
  methods: {
    ...mapActions('tag', [
      'initSportTags',
      'initTags'
    ]),
    ...mapActions('template', [
      'copyActive',
      'pasteClipboard'
    ]),
    ...mapMutations('template', [
      'REMOVE_ACTIVE',
      'PUT_ITEM'
    ]),
    itemChanged(item) {
      this.PUT_ITEM(item)
    }
  },
  computed: {
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    ...mapGetters('tagging', [
      'tags'
    ]),
    ...mapGetters('template', [
      'items',
      'clipboard'
    ]),
    useItems() {
      return this.init_items || this.items
    }
  }
} 
</script>

<style lang="scss" scoped>
  .buttontemplate-create {
    height: 100vh;
  }

  .octagon {
    width: 100%;
    height: 100%;
    background: red;
    position: relative;
  }
  .octagon:before {
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 29px solid red;
    border-left: 29px solid #ffffff;
    border-right: 29px solid #ffffff;
  }
  .octagon:after {
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 29px solid red;
    border-left: 29px solid #ffffff;
    border-right: 29px solid #ffffff;
  }

  .team > .vdr {
    border: none !important;
  }

</style>