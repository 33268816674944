<template>
  <v-sheet @click="handleClick">
    <v-card-title @click.prevent="handleClick">
      Edit item
    </v-card-title>
    <v-row>
      <v-col>
        <v-card-text>
          Font size
        </v-card-text>
        <v-select
          :items="Array.from(Array(41).keys()).slice(8, 41)"
          @change="PUT_ITEM({...activeItem, fontSize: $event})"
          :value="activeItem.fontSize"
        ></v-select>
      </v-col>
      <v-col>

      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card-text>
          Background color
        </v-card-text>
        <v-color-picker
          hide-canvas
          hide-sliders
          show-swatches
          hide-inputs
          :swatches="rgbaColors"
          @input="PUT_ITEM({...activeItem, backgroundColor: $event})"
          :value="activeItem.backgroundColor"
        >
        </v-color-picker>
      </v-col>
      <v-col cols="6">
        <v-card-text>
          Text color
        </v-card-text>
        <v-color-picker
          hide-canvas
          hide-sliders
          show-swatches
          hide-inputs
          :swatches="rgbaColors"
          @input="PUT_ITEM({...activeItem, textColor: $event})"
          :value="activeItem.textColor"
        >
        </v-color-picker>
      </v-col>
    </v-row>
    <v-row v-if="activeItem.type == 'group' || activeItem.type == 'dropdown'">
      <v-col>
        <v-card-text>
          Show group name
        </v-card-text>
        <v-switch
          :input-value="activeItem.showGroupName"
          @change="PUT_ITEM({...activeItem, showGroupName: $event})"
        >
        </v-switch>
      </v-col>
      <v-col>
        <v-card-text>
          Align content
        </v-card-text>
        <v-select
          :items="['left', 'right', 'center', 'space-between', 'space-around']"
          @input="PUT_ITEM({...activeItem, justifyContent: $event})"
          :value="activeItem.justifyContent"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-btn @click="REMOVE_ACTIVE()" class="mt-4">
        <v-icon>mdi-close</v-icon> Remove item (Del)
      </v-btn>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  shortcuts: {
    keydown: function(e) {
      if(e.key == 'Shift') {
        this.SET_PREVENT_DEACTIVATION(true)
      }
    }
  },
  data: () => ({
    rgbaColors: [
      ['#000000', '#FFFFFF', '#FF0000', '#FFFF00', '#008000'], // Group 1
      ['#0000FF', '#FF1493', '#DC143C', '#00FFFF', '#FFD700'], // Group 2
      ['#2E8B57', '#D2691E', '#6A5ACD', '#FA8072', '#FFA500']  // Group 3
    ]
  }),
  created() {
    // window.addEventListener("keyup", () => {
    //   this.SET_PREVENT_DEACTIVATION(false)
    // });
  },
  methods: {
    ...mapMutations('template', ['PUT_ITEM', 'SET_PREVENT_DEACTIVATION', 'REMOVE_ACTIVE']),
    handleClick() {
    }
  },
  computed: {
    ...mapGetters('template', ['activeItem'])
  }
}
</script>