var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-5",staticStyle:{"height":"100%","width":"100%","overflow-y":"scroll"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-text',[_vm._v(" Template name ")]),_c('v-text-field',{attrs:{"dense":"","solo":"","value":_vm.template_name},on:{"input":function($event){return _vm.SET_TEMPLATE_NAME($event)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('If selected, clicking a button saves the event automatically.'),expression:"'If selected, clicking a button saves the event automatically.'"}],attrs:{"input-value":_vm.settings.save_on_map,"value":_vm.settings.save_on_map,"label":"Save on map click"},on:{"change":function($event){return _vm.SET_SETTINGS({
        save_on_tag: _vm.settings.save_on_tag, save_on_map: $event
      })}}}),_c('v-switch',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('If selected, adding a map point saves the event automatically.'),expression:"'If selected, adding a map point saves the event automatically.'"}],attrs:{"input-value":_vm.settings.save_on_tag,"value":_vm.settings.save_on_tag,"label":"Save on tag click"},on:{"change":function($event){return _vm.SET_SETTINGS({
        save_on_tag: $event, save_on_map: _vm.settings.save_on_map
      })}}})],1)],1),_c('v-card-title',[_vm._v(" Add item ")]),_c('v-divider'),_c('v-sheet',[_c('v-card-title',[_vm._v(" Buttons ")]),_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.choosingTag = true; _vm.addingItem = {
          type: 'tag',
          shape: 'rectangle',
          text: 'Tag'
        }}}},[_vm._v(" Button ")])],1),_c('v-col',{staticClass:"center"},[_c('v-btn',{attrs:{"color":"primary","fab":""},on:{"click":function($event){_vm.choosingTag = true; _vm.addingItem = {
          type: 'tag',
          shape: 'circle',
          text: 'Tag'
        }}}},[_vm._v(" Button ")])],1)],1),_c('v-divider'),_c('v-card-title',[_vm._v(" Button groups ")]),_c('v-row',{staticClass:"py-5"},[_c('v-col',{staticClass:"center"},[_c('v-card',{on:{"click":function($event){_vm.choosingTagGroup = true; _vm.addingItem = {
          type: 'group'
        }}}},[_c('div',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v(" Basic ")]),_c('v-img',{attrs:{"src":require("../../assets/template_icon.png")}})],1)],1),_c('v-col',{staticClass:"center"},[_c('v-card',{on:{"click":function($event){_vm.choosingTagGroup = true; _vm.addingItem = {
          type: 'dropdown'
        }}}},[_c('div',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v(" Dropdown ")]),_c('v-img',{attrs:{"src":require("../../assets/template_icon_dropdown.png")}})],1)],1)],1),_c('v-divider'),_c('v-card-title',[_vm._v(" Map ")]),_c('v-row',_vm._l((_vm.map_bases),function(base){return _c('v-col',{key:base.id},[_c('v-card',{staticClass:"center",attrs:{"height":"150","width":"150"},on:{"click":function($event){_vm.addingItem = {type: 'map'}; _vm.handleItemAdded({
          map_base_id: base.id,
          map_base_url: base.url
        })}}},[_c('v-img',{attrs:{"src":base.url}})],1)],1)}),1)],1),_c('tag-adder-dialog',{attrs:{"sport_id":_vm.$route.params.sport_id,"show":_vm.choosingTag || _vm.choosingTagGroup,"addable":true,"onlyGroupChoosable":_vm.choosingTagGroup,"hideExtraButtons":true},on:{"close":function($event){_vm.choosingTag = false; _vm.choosingTagGroup = false;},"tag_clicked":function($event){return _vm.handleItemAdded($event)},"group_clicked":function($event){return _vm.handleItemAdded($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }